import React, { useEffect, useState } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Yahoo from "../assets/images/CLIENT/YAHOO.svg";
import Benzinga from "../assets/images/CLIENT/BENZINGA.svg";
import AP from "../assets/images/CLIENT/AP.svg";
import Morningstar from "../assets/images/CLIENT/MORNINGSTAR.svg";
import Asiaone from "../assets/images/CLIENT/ASIAONE.svg";
import Fox from "../assets/images/CLIENT/FOX.svg";
import Loader from "./Loader.js";
import Marquee from "react-fast-marquee";
import Profile from "../assets/images/CLIENT/about.webp";

export default function About() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, Set_loading] = React.useState(true);
  const [img, Set_img] = React.useState(
    require("../assets/images/Thumbnail.jpg")
  );
  const [vid, Set_vid] = React.useState(require("../assets/images/about.mp4"));

  useEffect(() => {
    if (img && vid) {
      Set_loading(false);
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="aboutus faq otherpage">
        <Header />
        <div className="aboutuspagesec new_aboutuspage">
          <div className="faq_img faq_img_aligned about_img">
            <h3 className="about_banner_maintxt robotoBold pt-4">
              Ignite Your Marketing Efforts <br className="about_bannerbr" />{" "}
              with Data-Driven NFTs
            </h3>
            <img src={Profile} className="img-fluid " />
          </div>
        </div>
        <div className="aboutbanner_bgcolor">
          <div className="container-fluid custom-container">
            <div className="about_banner_content mt-5">
              <p className="about_commonp">
                UNBLOK gives your business unparalleled access to customer data
                directly from their phone. You get more precise and
                comprehensive data than ever before, including location, keyword
                usage, and online shopping habits.{" "}
              </p>

              <p className="about_commonp">
                We have data that’s not available anywhere else. Not even
                Facebook or Google have this much insight – and you can access
                all this actionable data for FREE with our innovative new NFTs.
              </p>

              <h5 className="about_ban_title">Invaluable Insights</h5>
              <p className="about_commonp">
                Unlike other NFTs that give you ownership of images or objects,
                UNBLOK NFTs contain user data mined directly from their phones
                using our patent-pending keyboard.
              </p>
              <p className="about_commonp">
                Users willingly trade their data in exchange for crypto rewards
                – so you don’t have to worry about privacy violations and
                limitations from Facebook, Google, and other advertising
                platforms.{" "}
              </p>

              <p className="about_commonp">
                With our powerful NFTs, you can delve into the deepest corners
                of your customers' online activities: extracting keywords,
                understanding their preferences, and pinpointing their
                locations. This invaluable information allows you to refine your
                marketing strategies, target prospects with precision, and
                optimize your offerings to better meet their needs.
              </p>

              <p className="about_commonp">
                If your marketing costs have been skyrocketing due to new
                privacy tools and policy changes, UNBLOK can give you the
                precise marketing advantage you need to succeed.{" "}
              </p>

              <h5 className="about_ban_title">Cryptocurrency Collecting</h5>
              <p className="about_commonp">
                UNBLOK doesn’t just improve marketing for businesses. We also
                give consumers unprecedented control over how their data is used
                – and reward them with crypto payments.
              </p>

              <p className="about_commonp">
                By participating in our platform and exchanging their data for
                cryptocurrency, customers become active participants in the
                digital economy. They gain control over their own data and have
                the opportunity to monetize it, receiving tangible assets for
                their valuable information.{" "}
              </p>

              <p className="about_commonp">
                With UNBLOK, customers are no longer passive data providers;
                they become valued partners in shaping the digital landscape.
              </p>

              <p className="about_commonp">
                This also means that consumers are no longer driven to side-step
                data collection. As ad blockers and privacy tools become more
                prevalent, UNBLOK will keep an open line of communication
                between you and your customers.{" "}
              </p>

              <h5 className="about_ban_title">Benefits for Both Parties</h5>
              <p className="about_commonp">
                Traditional digital marketing involves taking user data without
                their consent, which creates animosity, distrust, and conflict:
                users naturally want to prevent companies from getting useful
                data that is essential to their growth and survival online.
              </p>
              <p className="about_commonp">
                UNBLOK creates a system that benefits everyone, all while being
                100% transparent.{" "}
              </p>

              <p className="about_commonp">
                Our NFTs collect user data directly from their phones'
                keyboards, all while paying customers with cryptocurrency
                through our app. This means your business gains an unprecedented
                behind-the-scenes look at your customers while they are
                incentivized for their participation.
              </p>

              <h5 className="about_ban_title">
                Global Insights that Go Beyond Individual Websites
              </h5>

              <p className="about_commonp">
                When you run ads on Google, Facebook, or TikTok, your ability to
                target users is limited: Facebook only knows what people do when
                they’re on Facebook.{" "}
              </p>

              <p className="about_commonp">
                But UNBLOK collects data directly from customers’s keyboards,
                across dozens of apps and websites.
              </p>

              <p className="about_commonp">
                This means you get insights that even the world’s most powerful
                advertising engines don’t have.
              </p>

              <h5 className="about_ban_title">Get Started Now</h5>

              <p className="about_commonp">
                Join us on this groundbreaking journey as we reshape the way
                businesses harness the power of customer data.{" "}
              </p>

              <p className="about_commonp">
                UNBLOK gives you a competitive edge and transforms the way you
                market and provide services to your customers. Be the first to
                use this new kind of customer data and grow your business before
                your competitors discover the best-kept secret in digital
                marketing.{" "}
              </p>
            </div>
            <div className="sectionAnother new_sectionAnother my-lg-5 my-md-3 my-sm-5 text-center">
              <p className="kr_asfearued robotoBold">As Featured In...</p>
            </div>
            <div className="pb-4">
              <Marquee
                speed={100}
                gradientWidth={50}
                autoFill={true}
                gradient={true}
              >
                <img src={Yahoo} className="img-fluid1" height="55" />
                <img src={Benzinga} className="img-fluid1" height="25" />
                <img src={AP} className="img-fluid1" height="55" />
                <img src={Morningstar} className="img-fluid1" height="35" />
                <img src={Asiaone} className="img-fluid1" height="30" />
                <img src={Fox} className="img-fluid1 fox_image" />
              </Marquee>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
