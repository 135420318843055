import axios from "axios";
import config from '../../lib/config';

export const ParamAccountAddr_Detail_Get = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/address/details/get`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}
export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
  try {
    
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/address/details/getorsave`,
      'data': Payload
    })
    return {

      data: Resp
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}
function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  }
  else {
    return '';
  }
}
export const getprofile = async (data, dispatch) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/getprofile`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data
    });

    return {
      loading: false,
      userValue: respData.data.userValue,
      admindata:respData.data.admindata
    }
  }
  catch (err) {
    return {
      loading: false,
      error: returnErr(err)
    }
  }
}
export const getSearchList = async (data) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/getSearchList`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data
    });
    return {
      loading: false,
      searchlist: respData.data
    }
  }
  catch (err) {
    return {
      loading: false,
      error: returnErr(err)
    }
  }
}
export async function coverimagevalidations(data) {
  var formData = new FormData();
  formData.append('currAddr', data.currAddr);
  formData.append('image', data.file)
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/test/coverimagevalidation`,
      'data': formData,
      'headers': {
        'Authorization': localStorage.user_token
    }
    })

    return {
      loading: false,
      error: respData.data
    }

  } catch (err) {
    return {
      loading: false,
      error: err.response.data
    }
  }
}
export const coverImage = async (data) => {
  
  try {
    const bodyFormData = new FormData();
    bodyFormData.append("coverimage", data.file);
    bodyFormData.append("accounts", data.currAddr);
    bodyFormData.append("from", data.from);


    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/test/coverImage/`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data: bodyFormData
    });

    return {
      loading: false,
      userValue: respData.data
    }

  }
  catch (err) {

    return {
      loading: false,
      error: err.response.data.errors
    }
  }
}
export const editprofile = async (data) => {
  try {
      const bodyFormData = new FormData();
      bodyFormData.append("name", data.name);
      bodyFormData.append("customurl", data.customurl);
      bodyFormData.append("bio", data.bio);
      bodyFormData.append("twitter", data.twitter);
      bodyFormData.append("photo", data.photo);
      bodyFormData.append("currAddr", data.currAddr);
       bodyFormData.append("youtube", data.youtube);
      bodyFormData.append("instagram", data.instagram);
      bodyFormData.append("email", data.email);
      bodyFormData.append("Portfolio", data.Portfolio);
      bodyFormData.append("walletAddress", data.walletAddress);
      bodyFormData.append("cover", data.cover);
      let respData = await axios({
          'method': 'post',
          'url': `${config.vUrl}/user/editprofile/`,
          'headers': {
              'Authorization': localStorage.user_token
          },
          data: bodyFormData
      });
      return {
          loading: false,
          userValue: respData.data
      }

  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
}
export const getUsdPrice = async (cryptoId) => {
  try {
    let Resp = await axios({
      'method': 'get',
      'url': 'https://api.coingecko.com/api/v3/simple/price',
    })
    return Resp;
  }
  catch (err) {
    return {
       error: err.response.data
    }
  }
}
export const updatePayment = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/updatePayment`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}
