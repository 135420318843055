import React, {
  useEffect,
  useState,
  useRef
} from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../../lib/config";

export default function Tokencard(props) {
  var { PlaceABid_Click, WalletUserDetails } = props;

  const [item, Set_item] = useState(props.item);
  const [infourl, Set_infourl] = React.useState('');
  const [allcurrOwner, Set_allcurrOwner] = React.useState([]);
  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  useEffect(() => {
    
    if(props.item!='')
    {
      
      var currentowners = item?.tokenowners_all?.map((item)=>item.balance>0&&item.tokenOwner)
      Set_allcurrOwner(currentowners)
      var url = `/info/${String(item.tokenName)}/${item.tokenowners_current.tokenOwner}/${item.contractAddress}/${item.tokenCounts}`
     var url_without_spaces = url.replace(" ", "")
      Set_infourl(url_without_spaces)

    }
 
  }, [props]);


const checkerror = (e, item) => {
  e.target.src = `${config.IPFS_IMG}/${item.ipfsimage}`;
};

    return(
        <>

        <div className="tokencardsec mb-4">
          <div className="tokencardsecimgsec">
          <div className="tokencardsecimg">

            <Link to={infourl}>
      
              <>
            {item &&
                  item?.image !== "" &&
                  (["webp", "gif", "jpg", "jpeg", "png"].includes(String(item?.image).split(".").pop()?.toLowerCase()))
 &&
                    (item?.ipfsimage?.split("/").includes("ipfs")===false)?
                        <img 
                        src={ item?.image !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`: `${config.IPFS_IMG}/${item?.ipfsimage}` }
                      onError={(e) => checkerror(e, item)}
                      alt="Collections" className="img-fluid"
                       />:   
   <img src={item?.ipfsimage?.split("/").includes("ipfs") && `${item?.ipfsimage}`}
   onError={(e) => checkerror(e, item)}
   alt="Collections" className="img-fluid " />
               
                  }
                  </>

                  </Link>
          </div>
        
          </div>
          
            <div className="tokencardinfo">
              <div className="tokencardinfosec">
              <Link
              to={infourl
              }
            >
              <h3>
                {item?.tokenName !=''? item?.tokenName:"#"+item?.tokenCounts}

              </h3>
            </Link>
              </div>

             

              <div className="tokencardinfoheade kr_tokencardinfoheade mt-0">
             {item &&
              item.higheBd != null &&
              item?.tokenowners_current?.tokenPrice !== null &&
              item?.higheBd?.tokenBidAmt != null ? (
                <div>
                <h4 className="text-left">Highest Bid</h4>
                <p>
                  {item.higheBd.tokenBidAmt}
                  {item.higheBd.CoinName}
                  <></>
                </p>
                </div>
              ) : (
                
                <div>
                {
                (item.tokenowners_current.tokenOwner).toLowerCase()===(config.adminaddr).toLowerCase()&&
                item.tokenowners_current.freeNft>0?
                <p>0.00 {item.tokenowners_current.CoinName}</p>
                :(item.tokenowners_current.tokenOwner).toLowerCase()===(config.adminaddr).toLowerCase()?
                <p>{(item?.tokenowners_current?.basePrice!=""?item?.tokenowners_current?.basePrice:item?.tokenowners_current?.tokenPrice) +" "+ item.tokenowners_current.CoinName}</p>
                :
                <p>{item?.tokenowners_current?.tokenPrice +" "+ item.tokenowners_current.CoinName}</p>
                }
                </div>
              )}

{
Wallet_Details.UserAccountAddr!==""
?
!allcurrOwner.includes(String(Wallet_Details.UserAccountAddr).toLowerCase())&&
item?.higheBd?.tokenBidAddress !== Wallet_Details.UserAccountAddr&&
String(item.tokenowners_current.tokenOwner).toLowerCase()!==String(Wallet_Details.UserAccountAddr).toLowerCase()
?
<button className="connectwallet tokencardlg_connectwallet" onClick={() =>PlaceABid_Click(item, item.tokenowners_current, item.tokenowners_current, item,WalletUserDetails)}>Bid</button>
:
!allcurrOwner?.includes(String(Wallet_Details.UserAccountAddr).toLowerCase())&&
item?.higheBd?.status==="pending" &&item?.higheBd?.tokenBidAddress === Wallet_Details.UserAccountAddr
?
<button className="connectwallet tokencard_connectwallet" onClick={() => PlaceABid_Click(item, item.tokenowners_current, item.tokenowners_current, item,WalletUserDetails)}>
Edit Bid
</button>
:String(item.tokenowners_current.tokenOwner).toLowerCase()===String(Wallet_Details.UserAccountAddr).toLowerCase()?
<button className="connectwallet tokencard_connectwallet">OWNED</button>
:allcurrOwner.includes(String(Wallet_Details.UserAccountAddr).toLowerCase())===true&&
<button className="connectwallet tokencard_connectwallet">Purchased</button>
:
!allcurrOwner.includes(String(Wallet_Details.UserAccountAddr).toLowerCase())&&
String(item.tokenowners_current.tokenOwner).toLowerCase()!==String(Wallet_Details.UserAccountAddr).toLowerCase()&&
<button className="connectwallet tokencardlg_connectwallet"  data-bs-toggle="modal" data-bs-target="#connect_wallet" >Bid
  </button>
}
              </div>
            </div>
                          
        </div>
   
     
        </>
    );
}