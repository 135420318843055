import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/Header/Head.js";
import { getunlockdatas } from "../actions/v1/token";
import moment from "moment";
import { useSelector } from "react-redux";
import { getsubscribe } from "../actions/v1/token";

export default function Subscribe() {
  let location = useHistory();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [NFTkey, setNFTkey] = useState([]);
  const [kycverified, setkycverified] = useState("");
  const [loading, setLoading] = useState(true);
  const [custData, setCustData] = useState([
    {
      sno: "1",
      keyword: "facebook",
      keyword: "facebook",
      location: "America",
      gender: "Male",
      dob: "28 Aug 2000",
      date: "11/11/1111",
      time: "08:09:55",
    },
    {
      sno: "2",
      keyword: "facebook",
      location: "America",
      gender: "Male",
      dob: "28 Aug 2000",
      date: "11/11/1111",
      time: "08:09:55",
    },
    {
      sno: "23",
      keyword: "facebook",
      keyword: "facebook",
      location: "America",
      gender: "Male",
      dob: "28 Aug 2000",
      date: "11/11/1111",
      time: "08:09:55",
    },
    {
      sno: "4",
      keyword: "facebook",
      keyword: "facebook",
      location: "America",
      gender: "Male",
      dob: "28 Aug 2000",
      date: "11/11/1111",
      time: "08:09:55",
    },
  ]);

  var statedata = location.location.state;

  useEffect(() => {
    if (statedata) {
      getunlockdatasfunc(statedata);
    }
  }, [statedata, Wallet_Details.UserAccountAddr]);

  const getunlockdatasfunc = async (data) => {
    if (Wallet_Details?.UserAccountAddr != "") {
      var datass = {
        currAdd: Wallet_Details.UserAccountAddr,
        tokenCount: data.tokencounts,
      };
      var authtoken = SubscribeStatus?.data?.data?.AuthorizationToken;
      var SubscribeStatus = await getsubscribe(datass);

      var enddate = new Date(SubscribeStatus?.data?.data?.SubscribeEnd);

      var subscribeData = SubscribeStatus?.data?.data?.SubscribedBy;
      var RemainingDaysCheck = subscribeData.filter(
        (ite) => Number(ite.RemainingDays) > 0
      );
      if (RemainingDaysCheck.length > 0) {
        var unlockdatas = await getunlockdatas(data);
        var keys = unlockdatas?.data?.getdailyDatas;
        var tabledata = keys?.map((item) => {
          var kewordData = item?.Keywords?.map((data) => {
            return `${data.Keyword} `;
          });
          console.log("awuduaiwd",kewordData)
          var userdetail = {
            createdAt: moment(item?.usersDetails?.createdAt).format("l"),
            Time: moment(item?.usersDetails?.createdAt).format("LTS"),
            DOB: moment(item?.usersDetails?.DOB).format("l"),
            language: item?.usersDetails?.language,
            gender: item?.usersDetails?.gender,
          };

          var data1 = {
            Keywords: kewordData,
            ...userdetail,
            location: item?.usersDetails?.location ?? "location",
          };
          return data1;
        });
        setLoading(false);

        if (tabledata) {
          setkycverified(data.tokenuser.KYCVRIFIED);
          setNFTkey(tabledata);
        }
      }
    }
  };

  const data = NFTkey;

  const columns = [
    {
      key: "Keywords",
      text: "Keywords",
      className: "Keywords",
      align: "left",
      sortable: false,
      cell: (val) => <div>{" " + val?.Keywords + " "}</div>,
    },

    {
      key: "createdAt",
      text: " Date ",
      align: "left",
      sortable: false,
    },
    {
      key: "Time",
      text: " Time",
      className: " Time",
      align: "left",
      sortable: false,
    },
  ];

  if (kycverified) {
    var cutcloumn = columns.splice(columns.length - 2);

    columns.push(
      kycverified == true && {
        key: "location",
        text: "location",
        className: "location",
        align: "left",
        sortable: false,
      },

      {
        key: "gender",
        text: "Gender",
        className: "UserDetails",
        align: "left",
        sortable: false,
      },
      kycverified == true && {
        key: "DOB",
        text: "Date of Birth",
        className: "UserDetails",
        align: "left",
        sortable: false,
      },

      kycverified == true && {
        key: "language",
        text: "Language",
        className: "UserDetails",
        align: "left",
        sortable: false,
      },
      ...cutcloumn
    );
  }

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "datas",
    no_data_text: "No data found!",
    button: {
      excel: kycverified ? true : false,
      print: kycverified ? true : false,
      csv: kycverified ? true : false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter datas",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,

    show_filter: true,
    show_pagination: true,
    show_info: true,
    sortable: false,
  };
  const [tag, setTag] = useState();
  useEffect(() => {
    if (data.length != 0) {
      excelData();
      TABLE();
    }
  }, [data]);
  const TABLE = () => {
    setTag(
      `<table class="table table-hover">
        <thead>
          <tr className="tabelrowe" id="tabelrowes">

            <th scope="col" className="bordering_radius_left">S.NO</th>
            <th scope="col">Keywords</th>
            <th scope="col">Location</th>
            <th scope="col">Gender</th>
            <th scope="col">Date of Birth</th>
            <th scope="col">Language</th>
            <th scope="col">Date</th>
            <th scope="col" className="bordering_radius_right">Time</th>

          </tr>
        </thead>
        <tbody>
          ${data.map((item, i) => {
            return `<tr>
                <td className="editpeors">${i + 1}</td>

                <td>${item.Keywords}</td>
                <td className="editpeors">${item.location}</td>
                <td>${item.gender}</td>
                <td className="editpeors">${item.DOB}</td>
                <td>${item.language}</td>
                <td className="editpeors">${item.createdAt}</td>
                <td>${item.Time}</td>

              </tr>`;
          })}

        </tbody>


      </table>`
    );
  };

  var excelData = () => {
    var hellllllo = data.map((val) => {
      var words = "";
      val.Keywords.map((int) => {
        words = words + "," + int;
      });
      //    words
      val.Keywords = words.substring(1);

      return val;
    });
  };

  const [arrData, setArrData] = useState([]);

  const oNchange = async (e) => {
    console.log("sdsvddvdfvdf",statedata,e);

    const { id, value } = e.target;
    var text = value.toLowerCase();

    if (text !== "") {


      var unlockdatas = await getunlockdatas({
        ...statedata,
        ...{ text: text },
      });

      if (unlockdatas?.data?.getdailyDatas) {
        var keys = unlockdatas?.data?.getdailyDatas;
        var tabledata = keys?.map((item) => {
          var kewordData = item?.Keywords?.map((data) => {
            return data.Keyword;
          });

          var userdetail = {
            createdAt: moment(item?.usersDetails?.createdAt).format("l"),
            Time: moment(item?.usersDetails?.createdAt).format("LTS"),
            DOB: moment(item?.usersDetails?.DOB).format("l"),
            language: item?.usersDetails?.language,
            gender: item?.usersDetails?.gender,
          };

          var data1 = {
            Keywords: kewordData,
            ...userdetail,
            location: item?.usersDetails?.location ?? "location",
          };
          return data1;
        });

        if (tabledata) {
          setArrData(tabledata);
        }
      }
    } else {
      setArrData([]);
    }
  };

  return (
    <>
      <Header />
      <div className="subscribes otherpage">
        <div className="subscribepagesec pb-5">
          <div className="container-fluid custom-container customerdata_tab">
            {Wallet_Details.UserAccountAddr ? (
              <>
                <div className="activity mt-5">
                  <div className="activitysec">
                    <div className="row justify-content-center">
                      <div className="col-md-6 col-sm-8 col-12">
                        <input
                          type="search"
                          className="unblockdata_searchbar"
                          placeholder="Search Keywords"
                          onChange={(e) => {
                            oNchange(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="table-responsive mt-5">
                      <table class="table table-hover">
                        <thead>
                          <tr className="tabelrowe" id="tabelrowes">
                            <th scope="col" className="bordering_radius_left">
                              Keywords
                            </th>
                            <th scope="col">Location</th>
                            <th scope="col">Date</th>
                            <th scope="col">Time</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Date of Birth</th>
                            <th scope="col" className="bordering_radius_right">
                              Language
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {arrData.length != 0 ? (
                            arrData.map((item, i) => {
                              const dobDate = new Date(item.DOB);
                              const month = dobDate.toLocaleString("default", {
                                month: "short",
                              });
                              const year = dobDate.getFullYear();
                              const formattedDOB = `${month} ${year}`;
                              return (
                                <tr>
                                  {console.log("item.Keywords",item.Keywords)}
                                  <td>{item?.Keywords?.map((val)=>{
                                    return ` ${val} `
                                  })} </td>
                                  {
                                    
                                    <td className="editpeors">
                                      {" "}
                                      {true ? item.location : " "}{" "}
                                    </td>
                                  }
                                  {
                                    <td className="editpeors">
                                      {" "}
                                      {true ? item.createdAt : " "}{" "}
                                    </td>
                                  }
                                  {<td>{true ? item.Time : " "}</td>}
                                  {<td>{kycverified ? item.gender : " "}</td>}
                                  {
                                    <td className="editpeors">
                                      {kycverified ? formattedDOB : " "}
                                    </td>
                                  }
                                  {<td>{kycverified ? item.language : " "}</td>}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={8}>
                                <div className="nod">
                                  <p className="nodataText">
                                    No Keywords Found{" "}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>Loading...</h1>
            )}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
