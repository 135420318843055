import { AES, enc } from 'crypto-js'
import config  from "../lib/config"

export const encodedata = (data) => {
    let encrpteddata = AES.encrypt(JSON.stringify(data), config.encodekey).toString()
    return encrpteddata;
  }
  
  export const decodedata = (data) => {
    let dataaaa = AES.decrypt(data, config.encodekey);
    return JSON.parse(dataaaa.toString(enc.Utf8))
  };