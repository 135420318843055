import React, { useEffect, useState } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Accordion from 'react-bootstrap/Accordion';
import Profile from "../assets/images/CLIENT/faq.webp";
import {faqlists} from '../actions/v1/report'

export default function Faq(props){
 
  useEffect(() => {
    window.scrollTo(0,0); 
},[]);
const [eventkey , setEventkey] = useState(0);
  const { ...rest } = props;
  const [faqlist,setfalist]=useState ([])

  useEffect(()=>{
    faqlistfunc()
  },[eventkey])
  const faqlistfunc=async()=>{
    var fql=await faqlists({dbname:"faq"})
    if(fql&&fql.data&&fql.data.soci){
      setfalist(fql.data.soci)
    }
  }
    return(
        <>
         <div className="explorepage faq otherpage">
        <Header />
     
      <div className="faq_img faq_img_aligned faqbg_img">
        <h3 className="about_banner_maintxt robotoBold pt-4">Frequently Asked Questions</h3>
        <img src={Profile} className="img-fluid faq_imagedt" />
    
    </div>
    <div className="faqbanner_bgcolor">
      <div className="container-fluid custom-container">
  
    <div className="faq_para pt-5">

    </div>
        <div className="tf-accordion"> 
                            <Accordion>
                                {faqlist.length>0 && faqlist.map((data,key) => {
                                    return(
                            <Accordion.Item eventKey={JSON.stringify(key)} className="tf-toggle">
                                <Accordion.Header className="tf-toggle-title robotoReg"><b>{data.question}</b></Accordion.Header>
                                <Accordion.Body>
                                <div className="robotoReg faq_anstxtclr disc_accord" dangerouslySetInnerHTML={{__html:data.answer}}></div>  
                                </Accordion.Body>
                            </Accordion.Item>
                            )})}
                            </Accordion>                                                      
                            </div> 

        </div>
     

       
        <Footer />
        </div>
        </div>
        </>
    )
}
