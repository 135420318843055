import React, { useState,useEffect,useRef } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import 'animate.css';
import Video from "../components/video/video"
import { useSelector } from "react-redux";
import HomeSkeleton from "./HomeSkeleton.js";
import {
  CollectiblesList_Home,
  topCreatorsApi,
  getBuyerSeller,
  liveaution,
  getpromotiondata
} from '../actions/v1/token';
import bannerimge from "../assets/images/banenrimg.png"
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Bannerimgae from "../assets/images/Group-88.png"
import Tokencard from "../components/seperate/tokencard.js"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import isEmpty from '../lib/isEmpty'
 import Load from "../assets/images/load_more.gif"
import config from "../lib/config"
import {Placeabid} from "../components/placeabid/placeabid"
import Loader from "./Loader.js";
import ReactSearchBox from "react-search-box";
import SearchBar from "../views/search_bar.js"
export default function Home(){
    var pauseVideo = document.getElementById('home_modalvideo');
  const videoPause = () =>{
    pauseVideo.pause();
    pauseVideo.currentTime=0;
  }
  const playvideo = () =>{
    pauseVideo.play();
  }

  useEffect(() =>{
    // document.getElementsByTagName('body')[0].classList.remove('modal-backdrop fade show');
    // document.getElementsByClassName('modal')[0].hide();
  })
 
  const options={
    loop:true,
    margin:30,
    nav:true,
    // smartSpeed: 4000,
    dots:true,
     items: 3,
    responsive:{
        0:{
            items:1
        },
     
        576:{
            items:2
        },
       
        992:{
            items:3
        },
        
        1200:{
          items:4
      }
    }
  
  }
  const options1={
    loop:true,
    margin:30,
    nav:true,
    smartSpeed: 2000,
    autoplay:true,
    dots:true,
     items: 1,
    //  autoplaySpeed:2000,
    responsive:{
        0:{
            items:1
        },
     
        576:{
            items:1
        },
       
        992:{
            items:1
        },
        
        1200:{
          items:1
      }
    }
  
  }


  var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {

      return <span>Waiting for Owner To Accept</span>
    } else {
      return <span> <span className="hourds">{formatTime(hours)} <span className="hrsname">Hrs</span></span> <span className="semicolan">:</span> <span className="hourds">{formatTime(minutes)} <span className="hrsname">Mins</span></span> <span className="semicolan">:</span> <span className="hourds">{formatTime(seconds)} <span className="hrsname">Sec</span></span> </span>;
    }
  };
  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }
  const[heart,setHeart]=useState(false);
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [TokenList, setTokenList] = useState([]);
  const [Creatorcount, setCreatorcount] = useState(0);
  const [item, Set_item] = useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  const [prmotiondata, set_prmotiondata] = React.useState({});
  const [liveauc, set_liveauc] = React.useState(false);

  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();

  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState({})
  const [loading, Set_loading] = React.useState(true);

  const [WalletUserDetails, setWalletUserDetails] = React.useState({});
  const [BidArray, setBidArray] = useState([])

  


  const data = [
    {
      key: "john",
      value: "John Doe",
    },
    {
      key: "jane",
      value: "Jane Doe",
    },
    {
      key: "mary",
      value: "Mary Phillips",
    },
    {
      key: "robert",
      value: "Robert",
    },
    {
      key: "karius",
      value: "Karius",
    },
  ];








  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  useEffect(() => {
    console.log("Baftyrtyrt6nner",`${config.Back_URL}/banner_images/art_bg.webp)`) 
       getInit();
    //getBuyerSellerFun('buyer');
  }, [Wallet_Details.UserAccountAddr,liveauc])


  async function getInit() {

// if(CatBasedTokenList.All.list.length ===0)
// {
  console.log("sfgnhsfgnghdndg",Wallet_Details?.UserAccountAddr);

  TokenListCall();
// }
    // getcmslistinhomes();
    // getcmslistinhomes1();
     promotionData();
    // CategoryListCall();
     timeAuctionFUnc();
  
  }


const promotionData=async()=>
{

  var resp = await getpromotiondata();
if(resp?.data?.data)
{
  set_prmotiondata(resp.data.data)
}
}


  const timeAuctionFUnc = async () => {
    var currAddr = Wallet_Details.UserAccountAddr;

    var payload = {
      limit: 6, from: 'Time', 
      currAddr: currAddr
    }
    var resp = await CollectiblesList_Home(payload);
    setWalletUserDetails(resp?.data?.walletUserDetails)

    if (resp && resp.data && resp.data.list && resp.data.list.length > 0) {
      Set_Time_Auction_List(resp.data.list)
console.log("sfffffffffffffffbbbbbbbbbbb",resp.data.list.length);
      var bids = resp.data.list.length>0&&resp.data.list.map((item,index)=>
        {
console.log("dsfdddddddddddddddddd",item);
if(!isEmpty(item.tokenowners_current) &&!isEmpty(item.higheBd))
{
  console.log("sfdfvsdfvsdfvdsf",item.higheBd,item.tokenowners_current,index);
  return item
}
        }).filter((ite)=>ite&&ite)
        console.log("vdsssdddddddddd",bids);
        setBidArray(bids)

    }
    else {
      Set_Time_Auction_List([])
    }
  }

  async function TokenListCall(data = {}) {
    var currAddr = Wallet_Details.UserAccountAddr
    console.log("ndfghmj,gfhmfhgjm",currAddr);
    var name = CatName;
    if (data.CatName) {
      name = data.CatName
    }
    // if(currAddr)
    // {
    var payload = {
      limit: 9,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
      currAddr: currAddr,
      CatName: name,
      from: 'Home',
    }
    
    // //console("loadMore>>>", payload, CatBasedTokenList[name], CatBasedTokenList)
    console.log("GNfghmghfjmghjmghj",payload);
    CatBasedTokenList.loader = true;
    // setCatBasedTokenList(CatBasedTokenList);
    
    var resp = await CollectiblesList_Home(payload);
    console.log("dfbdfbfdbfgb",resp.data);
    setWalletUserDetails(resp?.data?.walletUserDetails)
    console.log("xgfndgfngdhn",resp);
    Set_loading(false)
    CatBasedTokenList.loader = false;
    // setCatBasedTokenList(CatBasedTokenList);

    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      // CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      CatBasedTokenList[name].list =resp.data.list;

      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  // }
  }

/********Home */


    return(
 
        <>



<Placeabid
          ref={PlaceABidForwardRef}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          
        />











{loading?
 <Loader />
 :
        <>
        <Header />
        <Video />
 

{loading?
<HomeSkeleton />
 :

        <div className="home_header">
          {CatBasedTokenList[CatName].list.length>0&&
<div className="content-banner">
  {console.log("dfbdfgnbdfgndfg",`${config.Back_URL}/banner_images/bg2.webp`)}
  {/* <div className="row justify-content-center my-5">
    <div className="col-md-6 col-sm-8 col-12">
  <input type="search" className="unblockdata_searchbar" placeholder="Filter datas"/>
 
  </div>
  </div> */}

{/* <div  className="row justify-content-center">
  <div className="col-lg-6 col-md-8 col-12">
    <img className="img-fluid" src={require('../assets/images/Artworks/car_artwork.webp')}/>
  </div>
</div> */}

<div className="faq_img faq_img_aligned">
<img className="img-fluid" src={require('../assets/images/Artworks/car_artwork.webp')}/>
    </div>


<SearchBar/>

{/* <div className="container">
  <div className="row justify-content-center mb-4">
    <div className="col-lg-6 col-md-8 col-sm-8 col-12">
      <div className="common_react_searchbar_whole">
      <div className="common_react_searchbar">
      <i class="fa-solid fa-magnifying-glass searcher_glasss"></i>
    <ReactSearchBox
        placeholder="Search Data Tokens"
        value="Doe"
        data={data}
        callback={(record) => console.log(record)}
      />
      </div>
      </div>
    </div>
    </div>
    </div> */}
          <div className="container-fluid custom-container positionAbs">
            <div className="homebanerheight hide_homebannerheight">
           <div className="content">
            {/* <h1 className="robotoBold">Customer Data 3.0</h1> */}
            {/* <div className="header_main">
            <img src={headermain} />
            </div> */}
            <h1 className="robotoBold">Discover. Grow. Resell.</h1>
            <div className="learnmore">
            {/* <Link to={"/"} data-bs-toggle="modal"
             data-bs-target="#videolinks">Learn More <i class="fas fa-chevron-right"></i></Link> */}
             {/* <a href={config.learnmore}
               target="_blank"  rel='noreferrer'
             data-bs-toggle="modal"
             data-bs-target="#videolinks"
             >Learn More <i class="fas fa-chevron-right"></i></a> */}


            {/* <h4>NFT<span className="spanclasd">s</span></h4> */}
            </div>
            </div>
            </div>
            </div>
          </div>
          }
          <section className="mobile_content">
          <div className="homebanerheight">
           <div className="content">
            {/* <h1 className="robotoBold">Customer Data 3.0</h1> */}
            {/* <div className="header_main">
            <img src={headermain} />
            </div> */}
            <h1 className="robotoBold">Discover. Grow. Resell.</h1>
            <div className="learnmore">
              {/* <center>
              <div className="fm_learnmore_btn" data-bs-toggle="modal" data-bs-target="#video_modal">
              <p>Learn More</p>
              <img className="fm_playicon" src={require('../assets/images/CLIENT/play-circle.svg').default}/>
            </div>
            </center> */}
            {/* <Link to={"/"} data-bs-toggle="modal"
             data-bs-target="#videolinks">Learn More <i class="fas fa-chevron-right"></i></Link> */}
             {/* <a href={config.learnmore}
               target="_blank"  rel='noreferrer'
             data-bs-toggle="modal"
            data-bs-target="#videolinks"
             >Learn More <i class="fas fa-chevron-right"></i></a> */}
            {/* <h4>NFT<span className="spanclasd">s</span></h4> */}
            {/* <div className="fm_learnmore_btn" data-bs-toggle="modal" data-bs-target="#video_modal">
              <p>Learn More</p>
              <img className="fm_playicon" src={require('../assets/images/black/PlayIcon.svg').default}/>
            </div> */}
            </div>
            </div>
            

            <OwlCarousel className='owl-theme desktopview' {...options}>
           {
          prmotiondata&&prmotiondata.length>0&&prmotiondata.map((item,index)=>{
            return (

              <div className="owdlslider">
                <Link to={"/info/" + item.tokenOwner + '/' + item.contractAddress + '/' + item.tokenCounts}>

                  <img src={item?.image !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}` : `${config.IPFS_IMG}/${item?.ipfsimage}`} />
                </Link>
                <div className="owdlsliderpara">
                  {/* <h2>Neko Nation</h2> */}
                  <Link
                    to={"/info/" +
                      item?.tokenOwner +
                      "/" +
                      item?.contractAddress +
                      "/" +
                      item?.tokenCounts} //className="nameimg"
                  >

                    <h2>{item?.tokenName && item.tokenName}</h2>
                  </Link>
                  {/* <p> Floor Price:5.5 Avax................................ (99$) </p> */}

                  {/* <p> {"Floor Price:"+" "+item.tokenPrice+" "+item.CoinName} </p> */}
                  {/* <p> Floor Price:99$({item.CoinName === config.currencySymbol ? (Wallet_Details.currency_convertion.USD * item.tokenPrice).toFixed() :
                                   Wallet_Details.Token_convertion.filter(items => (items.label == item.CoinName)).length > 0 && (
                                     Wallet_Details.Token_convertion.filter(items => (items.label == item.CoinName))[0].USD * item.tokenPrice).toFixed()})</p> */}
                </div>
              </div>
              //     <div class="cube-container">
              //     <div class="photo-cube"> 
              //     <img src={banner.img} alt="" class="front" />
              //     <div class="left photo-desc">
              //         <h3>Rotate Cube</h3>
              //         <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, quas?</p>
              //         <a href="#" class="button">Download</a>
              //     </div>
              //     </div>
              // </div>
            );
          })}



{console.log("Fsntjdgyjghfjyrj",CatBasedTokenList)}

{/* {CatBasedTokenList&&CatBasedTokenList[CatName]&&CatBasedTokenList[CatName]?.list.length>0&&CatBasedTokenList[CatName].list.map((item)=>{
  console.log("ngdnjghmjjumjhmjm",item?.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}`: `${config.IPFS_IMG}/${item?.ipfsimage}`);
               <div className="owdlslider">
               {item &&
                  item?.image !== "" &&
                  (String(item?.image).split(".").pop() === "webp" ||
                    String(item.image).split(".").pop() === "WEBP" ||
                    String(item?.image).split(".").pop() === "gif" ||
                    String(item.image).split(".").pop() === "jpg" ||
                    String(item?.image).split(".").pop() === "GIF" ||
                    String(item.image).split(".").pop() === "JPG" ||
                    String(item?.image).split(".").pop() === "JPEG" ||
                    String(item.image).split(".").pop() === "jpeg" ||
                    String(item?.image).split(".").pop() === "png" ||
                    String(item.image).split(".").pop() === "PNG") &&
                 
                        <img src={ item?.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}`: `${config.IPFS_IMG}/${item?.ipfsimage}` }/>
                 
               
                  }
               <div className="owdlsliderpara">
                 {item?.tokenName && item.tokenName.slice(0, 10).concat("...")}

                 <p>Floor: 0.04 ETH</p>
               </div>
             </div>
})} */}



              </OwlCarousel>
              <OwlCarousel className='owl-theme tabview' {...options}>
              {console.log("mnbkjhvkhbvhbvjhkbv")}

           {CatBasedTokenList&&CatBasedTokenList[CatName]&&CatBasedTokenList[CatName]?.list.length>0&&CatBasedTokenList[CatName].list.map((item,index)=>(
       console.log("dfbdfbsdfbsfgb",item),
               <div className="owdlslider">
                            <Link to={"/info/" + item.tokenOwner + '/' + item.contractAddress + '/' + item.tokenCounts}>

               <img src={ item?.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}`: `${config.IPFS_IMG}/${item?.ipfsimage}` }/>
               </Link>
               <div className="owdlsliderpara">
                 {/* <h2>Neko Nation</h2> */}
                 <Link
              to={
                "/info/" +
                item?.tokenOwner +
                "/" +
                item?.contractAddress +
                "/" +
                item?.tokenCounts
              } 
            >
                <h2>{item?.tokenName && item.tokenName}</h2> 
</Link>
                 <p>Floor Price: {item.tokenPrice} {item.CoinName}</p>
               </div>
             </div>
        //     <div class="cube-container">
        //     <div class="photo-cube">
        //     <img src={banner.img} alt="" class="front" />
            
        //     <div class="left photo-desc">
        //         <h3>Rotate Cube</h3>
        //         <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, quas?</p>
        //         <a href="#" class="button">Download</a>
        //     </div>
        //     </div>
        // </div>
           ))}



{console.log("Fsntjdgyjghfjyrj",CatBasedTokenList[CatName].list)}

{/* {CatBasedTokenList&&CatBasedTokenList[CatName]&&CatBasedTokenList[CatName]?.list.length>0&&CatBasedTokenList[CatName].list.map((item)=>{
  console.log("ngdnjghmjjumjhmjm",item?.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}`: `${config.IPFS_IMG}/${item?.ipfsimage}`);
               <div className="owdlslider">
               {item &&
                  item?.image !== "" &&
                  (String(item?.image).split(".").pop() === "webp" ||
                    String(item.image).split(".").pop() === "WEBP" ||
                    String(item?.image).split(".").pop() === "gif" ||
                    String(item.image).split(".").pop() === "jpg" ||
                    String(item?.image).split(".").pop() === "GIF" ||
                    String(item.image).split(".").pop() === "JPG" ||
                    String(item?.image).split(".").pop() === "JPEG" ||
                    String(item.image).split(".").pop() === "jpeg" ||
                    String(item?.image).split(".").pop() === "png" ||
                    String(item.image).split(".").pop() === "PNG") &&
                 
                        <img src={ item?.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}`: `${config.IPFS_IMG}/${item?.ipfsimage}` }/>
                 
               
                  }
               <div className="owdlsliderpara">
                 {item?.tokenName && item.tokenName.slice(0, 10).concat("...")}

                 <p>Floor: 0.04 ETH</p>
               </div>
             </div>
})} */}

              </OwlCarousel>
              <OwlCarousel className='owl-theme mobileview' {...options}>
           {CatBasedTokenList&&CatBasedTokenList[CatName]&&CatBasedTokenList[CatName]?.list.length>0&&CatBasedTokenList[CatName].list.map((item,index)=>(
            console.log("fgndfgndfgndghn",`${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}` ),
               <div className="owdlslider">
                 <Link to={"/info/" + item.tokenOwner + '/' + item.contractAddress + '/' + item.tokenCounts}>

               <img src={ item?.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}`: `${config.IPFS_IMG}/${item?.ipfsimage}` }/>
              </Link>
              <Link
              to={
                "/info/" +
                item?.tokenOwner +
                "/" +
                item?.contractAddress +
                "/" +
                item?.tokenCounts
              } //className="nameimg"
            >
               <div className="owdlsliderpara">
                 {/* <h2>Neko Nation</h2> */}
                <h2>{item?.tokenName && item.tokenName}</h2> 

                 <p>Floor Price: {item.tokenPrice} {item.CoinName}</p>
               </div>
               </Link>
             </div>
        //     <div class="cube-container">
        //     <div class="photo-cube">
        //     <img src={banner.img} alt="" class="front" />
            
        //     <div class="left photo-desc">
        //         <h3>Rotate Cube</h3>
        //         <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, quas?</p>
        //         <a href="#" class="button">Download</a>
        //     </div>
        //     </div>
        // </div>
           ))}



{console.log("Fsntjdgyjghfjyrj",loading)}


              </OwlCarousel>
            </div>
          </section>

          <section className="subscribe home_subscribe">
          
          <div className="container-fluid custom-container">
          <center className="fm_learnmore_windhide">
              <div className="fm_learnmore_btn" onClick={playvideo} data-bs-toggle="modal" data-bs-target="#video_modal">
              <p>Watch the video</p>
              <img className="fm_playicon" src={require('../assets/images/black/Playvideo.svg').default}/>
            </div>
            </center>
          
            {/* <div className="subscribebnft newsubscribenft">

            

              <p className=" mob_minus robotoReg">Fuel your business’s growth with precision consumer data.</p>
              <div className="fm_newdtl_wrap">

              <div className="fm_newdtl_whole">
                <img className="fm_newdtl_img" src={require('../assets/images/black/Keyboard_last.svg').default}/>
                <p className="fm_newdtl_txt robotoReg">Get an <span className="homepage_darker_span"> inside look at your customer’s habits</span> through their phone’s keyboard. </p>
              </div>
              <div className="fm_newdtl_whole">
                <img className="fm_newdtl_img" src={require('../assets/images/black/UserAppoved.svg').default}/>
                <p className="fm_newdtl_txt robotoReg">Customers voluntarily share their phone’s <span className="homepage_darker_span">location and keyword usage</span> with you. 
</p>
              </div>
              <div className="fm_newdtl_whole">
                <img className="fm_newdtl_img" src={require('../assets/images/black/cloud_last.svg').default}/>
                <p className="fm_newdtl_txt robotoReg">Gain access to <span className="homepage_darker_span">ecure data through NFTs,</span>s which you can purchase, use, and resell.

</p>
              </div>
              </div>

              <p className="fm_getting_txt fm_getting_txttop robotoReg">All this real-time data is collected to power future GPT-integration and stored on the blockchain with the customer’s explicit permission, making it a 100% legal way to “spy” on customers.</p>

              <p className="fm_getting_txt robotoReg">We’re using the latest technology and leveraging AI in ways that actually create meaningful improvements to your business.</p>
              <p className="fm_getting_txt robotoReg">Maximize your ad spend and optimize your marketing strategies with user data that’s not available anywhere else. </p>


                <h2 className="new_subscribe_mob">Subscribe to Our NFTs</h2>
                <p className="new_subscribe_mob">Non-fungible tokens (NFTs) require a daily subscription for access to up-to-date customer data.</p>

                
            </div> */}
            
          </div>
          </section>
       {   loading?
  <Loader />
  :
          <section className="recentlyadded">
            <div className="container">
            <div className="kodu"></div>
            </div>
          <div className="container custom-container">
            {/* <h2>Recently Added NFTs</h2> */}
            <div className="row slicing_row">
              
              
             
            <h2 className="fm_topdata_ttl robotoBold">Top Data Tokens</h2>


{
                          (CatBasedTokenList
                            && CatName
                            && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list
                            && CatBasedTokenList[CatName].list.length > 0)
                            ? (CatBasedTokenList[CatName].list.map((item) => {
                              {console.log("dfbdhfbhsdf",(CatBasedTokenList[CatName].list))}

                              return (
                                (isEmpty(item.tokenowners_current) !== true )?
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 col-6 card_client">
                                {/* col-lg-3 col-md-6 col-sm-6 */}
                                  <Tokencard
                                  WalletUserDetails={WalletUserDetails!=undefined?WalletUserDetails:""}
                                    item={item}
                                    Set_item={Set_item}
                                    LikedTokenList={LikedTokenList}
                                    setLikedTokenList={setLikedTokenList}
                                    hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                   // PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                   // PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                   PlaceABid_Click={PlaceABidForwardRef?.current?.PlaceABid_Click}
                                    Set_Bids={Set_Bids}
                                    Bids={item.myBid}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance={Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay={Set_YouWillPay}
                                    Set_YouWillPayFee={Set_YouWillPayFee}
                                    Set_YouWillGet={Set_YouWillGet}
                                    // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                   // Burn_Click={BurnForwardRef.current.Burn_Click}
                                   // CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    // WalletConnected={WalletConnected}
                                   // SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                  //  ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                    setConvertVal={setConvertVal}
                                    convertVal={convertVal}
                                    curraddress={Wallet_Details.UserAccountAddr!=""?Wallet_Details.UserAccountAddr:""}

                                  />
                                
                                </div>
                                  :
                                  ("")
                              )
                             
                            })) :
                         
                            // <div className="proposal_panel_overall text-center">
                            <div className="text-center py-5 col-12 notfound">
                            <div className="text-center py-3  no_items_row">
                              {/* <p className="not_found_text">No items found</p> */}
                              <p className="not_found_text_sub">We currently don't have any active auctions. Please come back soon or search our marketplace for data NFTs.</p>
                              <div className="mt-3">
                                {/* <button className="create_btn"><Link to="/">Browse Marketplace</Link></button> */}

                              </div>
                            </div>
                          </div>
                        }

            </div>
            </div>
            <div className="text-center mt-2">
            <Link to="/explore/All"><button className="connectwallet connectwallet_mobbot"> Explore More </button></Link>
            </div>
          </section>}
          {   loading?
  <Loader />
  :
          <section className="live_auctions" id="Liveaudion">
             <div className="container">
            <div className="kodu"></div>
            </div>
          <h2 className="robotoBold">Live Auctions</h2>
          <div className="container custom-container">
           
            <div className="row slicing_row">
{console.log("hcffxfgxgfxgfvvzdzsd",BidArray)}


{BidArray.length>0?(BidArray.map((item) => {
console.log("sdfbsfdbsfd",item);
                            return (
<div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 col-6 ">
{/* <p >hiiiiiiiiiiii</p> */}
                              <Tokencard
                              item={item}
                              Set_item={Set_item}
                              LikedTokenList={LikedTokenList}
                              setLikedTokenList={setLikedTokenList}
                              hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                              // PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                              // PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                              PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                              Set_Bids={Set_Bids}
                              Bids={item.myBid}
                              Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                              Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                              Set_MyTokenBalance={Set_MyTokenBalance}
                              Set_MyTokenDetail={Set_MyTokenDetail}
                              Set_AllowedQuantity={Set_AllowedQuantity}
                              Set_YouWillPay={Set_YouWillPay}
                              Set_YouWillPayFee={Set_YouWillPayFee}
                              Set_YouWillGet={Set_YouWillGet}
                              // Burn_Click={BurnForwardRef.current.Burn_Click}
                              // CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                              // SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                              // ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                              setConvertVal={setConvertVal}
                              convertVal={convertVal}
                              curraddress={Wallet_Details.UserAccountAddr!=""?Wallet_Details.UserAccountAddr:""}
                            />
                            </div>
                      )
                    }))
                    :
                    <div className="text-center pb-5 col-12 notfound">
                    <div className="text-center pb-3 pt-2 no_items_row">
                      {/* <p className="not_found_text">No Bids Found</p> */}
                      <p className="not_found_text_sub newtext_clrliveauc robotoReg footer_xpln">Please come back soon for live auctions or search the marketplace for data NFTs.</p>
                      <div className="mt-3">
                        {/* <button className="create_btn"><Link to="/">Browse Marketplace</Link></button> */}

                      </div>
                    </div>
                  </div>
                    }
 


            </div>
            {
            //!isEmpty(Time_Auction_List)?
            <div className="text-center mt-2">

            
            <Link to="/explore/All"><button className="connectwallet">Explore More </button></Link>



            </div>
            // :('')
}
            </div>
          </section>
          }
            </div>
}


{/* video modal */}

<div class="modal fade " id="video_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg  modal-dialog-centered modal-dialog-scrollable">
    {   
    <div class="modal-content">
      
      <div class="modal-body">
      
      <video width="100%" height="100%" id="home_modalvideo" autoplay controls >
  <source src={require('../assets/images/black/watchdemo.mp4')} type="video/mp4"/>
</video>
      
       

      <center>
<button
                  className="bidbtn modal_cancelbtn"
                  data-bs-dismiss="modal"
                  onClick={videoPause}
                >
                  Cancel
                </button>
                </center>

      
       
      </div>
     
    </div>
    
}
  </div>
</div>
{/* end of video modal */}
        <Footer />
 </>
}
        </>
    )
}