import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Header from "../components/Header/Head";
import { faqlists } from "../actions/v1/report";
import Profile from "../assets/images/Artworks/fprivacy.webp";
import Footer from "../components/Footer/Footer.js";
import { Link } from "react-router-dom";
import HeaderLinks from "../components/Header/Head";
import ReactHtmlParser from "react-html-parser";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacypolicy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { ...rest } = props;
  const [eventkey, setEventkey] = useState(0);
  const [faqlist, setfalist] = useState([]);
  const [TopContent, setTopContent] = useState([]);
  useEffect(() => {
    faqlistfunc();
  }, [eventkey]);
  const faqlistfunc = async () => {
    var fql = await faqlists({ dbname: "privacy" });

    if (fql && fql.data && fql.data.soci) {
      setfalist(
        fql?.data?.soci?.length > 0 &&
          fql?.data?.soci?.map((ite) => (ite?.TopContent ? false : ite))
      )?.filter((ite) => ite && ite);

      setTopContent(
        fql?.data?.soci?.length > 0 &&
          fql?.data?.soci
            ?.map((ite) => (ite?.TopContent ? ite.TopContent : false))
            ?.filter((ite) => ite && ite)[0]
      );
    }
  };

  return (
    <div className="inner_header otherpage">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/">
            <img
              src={require("../assets/images/profile.png")}
              alt="logo"
              className="img-fluid"
            />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className="container-fluid p-0">
        <div className="termsofservice">
          <div className="faq_img img_aligned privacy_img">
            <h3 className="about_banner_maintxt robotoBold pt-4">
              Privacy Policy
            </h3>
            <img src={Profile} className="img-fluid mt-4" />
          </div>
        </div>
      </div>
      <div className="privacybanner_bgcolor">
        <div className="container-fluid custom-container">
          <div className="termsofservice">
            <div className="disc_accord pt-5">
              {ReactHtmlParser(TopContent)}
            </div>

            <Accordion className="disc_removelast">
              {faqlist.length > 0 &&
                faqlist
                  .filter((ite) => ite && ite)
                  .map((data, key) => {
                    return (
                      <Accordion.Item
                        eventKey={JSON.stringify(key)}
                        className="tf-toggle disc_accord hide_item"
                      >
                        <Accordion.Header className="tf-toggle-title">
                          <h6>{data.question}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="aboutpage_cmstxts faq_anstxtclr disc_accord"
                            dangerouslySetInnerHTML={{ __html: data.answer }}
                          ></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
            </Accordion>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
