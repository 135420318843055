// import Web3 from 'web3';


import Web3 from "web3"
import config from '../../lib/config'

export const getReceipt=async(Web3Utils,approveCall)=>{
        //console("gnkrfebngkrebn",config.RPC);
        // var receipt = null
        var web3 = new Web3(config.BNBPROVIDER)
        var receipt =  await web3.eth.getTransactionReceipt(approveCall)
        //////console("receipt",receipt)
        //////console("receipt 1",receipt)
     
        return receipt
//       })
      
       
}