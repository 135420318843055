export const countryCode = [
  { label: "Albania", value: "AL" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bolivia", value: "BO" },
  { label: "Brazil", value: "BR" },
  { label: "Bulgaria", value: "BG" },
  { label: "Cambodia", value: "KH" },
  { label: "Canada", value: "CA" },
  { label: "Croatia", value: "HR" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "Germany", value: "DE" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Guernsey", value: "GG" },
  { label: "Hong Kong", value: "HK" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Iran", value: "IR" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Latvia", value: "LV" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Luxembourg", value: "LU" },
  { label: "Malaysia", value: "MY" },
  { label: "Malta", value: "MT" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritius", value: "MU" },
  { label: "Mexico", value: "MX" },
  { label: "Moldova", value: "MD" },
  { label: "Montenegro", value: "ME" },
  { label: "Myanmar", value: "MM" },
  { label: "Netherlands", value: "NL" },
  { label: "New Zealand", value: "NZ" },
  { label: "Norway", value: "NO" },
  { label: "Pakistan", value: "PK" },
  { label: "Panama", value: "PA" },
  { label: "Poland", value: "PL" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Romania", value: "RO" },
  { label: "Rwanda", value: "RW" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "South Africa", value: "ZA" },
  { label: "Spain", value: "ES" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Tonga", value: "TO" },
  { label: "Tunisia", value: "TN" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "Vanuatu", value: "VU" },
  { label: "Vietnam", value: "VN" }
];
