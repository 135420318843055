import React, { forwardRef, useImperativeHandle } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getReceipt } from "../../actions/v1/getReceiptFunc";
import Web3 from "web3";
import config from "../../lib/config";
import trade from "../../ABI/Trade.json";
import { toast } from "react-toastify";
import isEmpty from "../../lib/isEmpty";

import {
  TokenPriceChange_update_Action,
  checkOtherPlatformDetais1155,
} from "../../actions/v1/token";

toast.configure();
let toasterOption = config.toasterOption;

export const CancelOrder = forwardRef((props, ref) => {
  const history = useHistory();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState("start");
  const [TokenPrice, Set_TokenPrice] = React.useState(0);
  const [Owners, Set_Owners] = React.useState({});
  const [Item_Owner, Set_Item_Owner] = React.useState({});
  const [Items, Set_Item] = React.useState({});
  async function FormSubmit() {
    if (Wallet_Details.providerss) {
      var web3 = new Web3(Wallet_Details.providerss);
      if (web3) {
        try {
          var CoursetroContract = new web3.eth.Contract(trade, config.trade);

          var handle = null;
          var receipt = null;
          Set_FormSubmitLoading("processing");


          const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
            //value: subAmount
        };
          var encoded  = await CoursetroContract.methods.cancelOrder(Items.tokenCounts)
          var gasprice = await web3.eth.getGasPrice();
          var gaslimit = await encoded.estimateGas(Transcation)
          var gasLimit = web3.utils.toHex(gaslimit);
          var gasPrice = web3.utils.toHex(gasprice);
          await CoursetroContract.methods
            .cancelOrder(Items.tokenCounts)
            .send({ from: Wallet_Details.Accounts,gasLimit:gasLimit,gasPrice:gasPrice })
            .on("transactionHash", async (transactionHash) => {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash);
                clr1();
              }, 8000);
            });
        } catch (error) {
          Set_FormSubmitLoading("try");
          toast.error("Order not placed", toasterOption);
        }
      }
      async function clr1() {
        if (receipt != null) {
          clearInterval(handle);
          if (receipt.status === true) {
            var postData = {
              tokenOwner: Wallet_Details.UserAccountAddr,
              tokenCounts: Items.tokenCounts,
              tokenPrice: 0,
              blockHash: receipt.blockHash,
              transactionHash: receipt.transactionHash,
              CoinName: Item_Owner.CoinName,
            };
            var Resp = await TokenPriceChange_update_Action(postData);
            if (
              Resp &&
              Resp.data &&
              Resp.data.RetType &&
              Resp.data.RetType === "success"
            ) {
              Set_FormSubmitLoading("done");

              toast.success(
                "Collectible cancelled successfully",
                toasterOption
              );
              window.$(".modal").modal("hide");
              setTimeout(() => {
                history.push("/my-items");
              }, 5000);
            }
          }
        }
      }
    }
  }

  useImperativeHandle(ref, () => ({
    async CancelOrder_Click(item, Owner, itemOwner) {
      if (Wallet_Details.WalletConnected === "true") {
        var web3 = new Web3(Wallet_Details.providerss);
        var balance = await checkOtherPlatformDetais1155(
          item,
          itemOwner,
          item.type,
          web3
        );

        var currOwner =
          item.tokenowners_current.length > 0 &&
          item.tokenowners_current.filter(
            (item) => item.tokenOwner === Wallet_Details.UserAccountAddr
          );
        if (currOwner[0]?.balance > Number(balance)) {
          toast.warning("Nft purchase limit exceed", toasterOption);
          setTimeout(() => {
            history.push("/");
          }, 1000);
          return false;
        } else {
          props.Set_HitItem(item);
          Set_Item(item);
          Set_TokenPrice(0);
          Set_Owners(Owner);
          Set_Item_Owner(itemOwner);
          window.$("#cancelOrder").modal("show");
        }
      } else {
        toast.warning("please connect your wallet");

        window.$("#connect-wallet").modal("show");
      }
    },
  }));

  return (
    <>
      <div
        className="modal fade"
        id="cancelOrder"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Cancel Order
              </h5>
            </div>
            <div className="modal-body">
              <h6 className="bidnowconent">You are about to Cancel order</h6>

              <h3 className="ownernane">{Items.tokenName}</h3>
              {!isEmpty(Owners) && Owners.name !== "" ? (
                <h3 className="ownernane" title={"Owner : " + Owners.name}>
                  {Owners.name}
                </h3>
              ) : (
                <h3
                  className="owneradd"
                  title={"Owner : " + Item_Owner && Item_Owner.tokenOwner}
                >
                  {String(Item_Owner && Item_Owner.tokenOwner)
                    .slice(0, 10)
                    .concat("...")}
                </h3>
              )}
              <h5> {Item_Owner.tokenPrice + " " + Item_Owner.CoinName}</h5>
            </div>
            <div className="modal-footer">
              <button
                class="buybtn"
                onClick={
                  (FormSubmitLoading === "start" ||
                    FormSubmitLoading === "try") &&
                  FormSubmit
                }
                disabled={
                  FormSubmitLoading === "processing" ||
                  FormSubmitLoading === "done"
                }
              >
                {FormSubmitLoading === "processing" && (
                  <i
                    className="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                    id="circle1"
                  ></i>
                )}
                {FormSubmitLoading === "processing" && "In-Progress"}
                {FormSubmitLoading === "done" && "Done"}
                {FormSubmitLoading === "start" && "Start"}
                {FormSubmitLoading === "try" && "Try-Again"}
              </button>
              <button class="bidbtn" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
