import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Header from "../components/Header/Head";
import Profile from "../assets/images/Artworks/fdisc.webp";
import Footer from "../components/Footer/Footer.js";
import ReactHtmlParser from 'react-html-parser';
import {faqlists} from '../actions/v1/report'

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Disclaimer(props) {
  const [eventkey , setEventkey] = useState(0);
  const [faqlist,setfalist]=useState ([])
  const [TopContent,setTopContent]=useState ([])

  useEffect(()=>{
    faqlistfunc()
  },[eventkey])
  const faqlistfunc=async()=>{
    var fql=await faqlists({dbname:"disclaimer"})
   
    if(fql&&fql.data&&fql.data.soci){
      setfalist(fql?.data?.soci?.length>0&&fql?.data?.soci?.map((ite)=>ite?.TopContent?false:ite))?.filter((ite)=>ite&&ite)
      
      setTopContent(fql?.data?.soci?.length>0&&fql?.data?.soci?.map((ite)=>ite?.TopContent?ite.TopContent:false)?.filter((ite)=>ite&&ite)[0])
    }
  }

  return (
    <div className="inner_header otherpage">
    <Header />
      <ScrollToTopOnMount/>
      <div className="container-fluid p-0">
      <div className="termsofservice">
      <div className="faq_img img_aligned disclaimer_img">
      <h3 className="about_banner_maintxt robotoBold pt-4">Disclaimer</h3>
        <img src={Profile} className="img-fluid" />
    </div>
    </div>
      </div>
      <div className="discbanner_bgcolor mt-5">
      <div className="container-fluid custom-container">
        <div className="termsofservice">
             
    <div className="disc_accord">{ReactHtmlParser(TopContent)}</div>
  
<Accordion className="disc_removelast">
                                {faqlist.length>0 && faqlist.filter((ite)=>ite&&ite).map((data,key) => {
                                    return(
                            <Accordion.Item eventKey={JSON.stringify(key)} className="tf-toggle disc_accord">
                                <Accordion.Header className="tf-toggle-title"><h6>{data.question}</h6></Accordion.Header>
                                <Accordion.Body>
                                <div className="robotoReg faq_anstxtclr disc_accord" dangerouslySetInnerHTML={{__html:data.answer}}></div>  
                                </Accordion.Body>
                            </Accordion.Item>
                            )})}
                            </Accordion>  

        </div>
      </div>
      <Footer/>
      </div>
    </div>
  );
}
